import { useState, useEffect } from 'react'
import '../App.css'
import '../Poker.css'
import Player from './Player'
import Dealer from './Dealer'
import { pokerPlayers } from '../data/players.js'

function PokerSim() {
  const [dealStage, setDealStage] = useState('preflop')
  const [deck, setDeck] = useState([])
  const [playersWithCards, setPlayersWithCards] = useState([])
  const [communityCards, setCommunityCards] = useState([])
  const [dealing, setDealing] = useState(false)
  const [selectedButton, setSelectedButton] = useState()
  const [selectedHero, setSelectedHero] = useState()
  const [selectedVillain, setSelectedVillain] = useState()
  const [heroCardsInput, setHeroCardsInput] = useState('')
  const [villainCardsInput, setVillainCardsInput] = useState('')
  const [heroError, setHeroError] = useState('') // Error message for hero input
  const [villainError, setVillainError] = useState('') // Error message for villain input

  useEffect(() => {
    const newDeck = createDeck()
    shuffleDeck(newDeck)
    setDeck(newDeck)
    setPlayersWithCards(pokerPlayers.map((player) => ({ ...player, cards: [] })))
  }, [])

  function createDeck() {
    const suits = ['♠', '♣', '♥', '♦']
    const values = ['2', '3', '4', '5', '6', '7', '8', '9', 'T', 'J', 'Q', 'K', 'A']
    const deck = []

    for (const suit of suits) {
      for (const value of values) {
        deck.push({ value, suit })
      }
    }

    return deck
  }

  function shuffleDeck(deck) {
    for (let i = deck.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[deck[i], deck[j]] = [deck[j], deck[i]]
    }
  }

  // Parse card input, e.g., 'Ac' -> { value: 'A', suit: '♣' }
  function parseCard(cardStr) {
    if (cardStr.length !== 2) {
      return null
    }
    const value = cardStr[0].toUpperCase()
    const suitMap = {
      c: '♣',
      d: '♦',
      h: '♥',
      s: '♠',
    }
    const suit = suitMap[cardStr[1].toLowerCase()]
    return { value, suit }
  }

  // Helper function to check if two cards are the same
  function isSameCard(card1, card2) {
    return card1.value === card2.value && card1.suit === card2.suit
  }

  // Helper function to check for duplicates within the same hand
  function hasDuplicateCards(cards) {
    return cards[0] && cards[1] && isSameCard(cards[0], cards[1])
  }

  // Regex to match valid card input (value + suit)
  const validCardPattern = /^[2-9TJQKA][cdhs]$/i

  // Function to validate card input format (value + suit)
  function isValidCard(cardStr) {
    return validCardPattern.test(cardStr)
  }

  // Validate hero and villain card inputs and set error messages if needed
  function validateCardsInput() {
    let valid = true
    setHeroError('')
    setVillainError('')

    if (heroCardsInput) {
      const heroInputCards = heroCardsInput.split(' ')
      if (heroInputCards.length !== 2) {
        setHeroError('Please enter exactly two cards for the hero.')
        valid = false
      } else if (!heroInputCards.every(isValidCard)) {
        setHeroError('Invalid card format for hero. Please use format like Ac, Kd.')
        valid = false
      } else if (hasDuplicateCards(heroInputCards.map(parseCard))) {
        setHeroError('Hero cannot have two of the same card.')
        valid = false
      }
    }

    if (villainCardsInput) {
      const villainInputCards = villainCardsInput.split(' ')
      if (villainInputCards.length !== 2) {
        setVillainError('Please enter exactly two cards for the villain.')
        valid = false
      } else if (!villainInputCards.every(isValidCard)) {
        setVillainError('Invalid card format for villain. Please use format like Ac, Kd.')
        valid = false
      } else if (hasDuplicateCards(villainInputCards.map(parseCard))) {
        setVillainError('Villain cannot have two of the same card.')
        valid = false
      }

      // Check if villain cards are the same as hero cards
      const heroCards = heroCardsInput ? heroCardsInput.split(' ').map(parseCard) : []
      const villainCards = villainInputCards.map(parseCard)
      const hasDuplicateWithHero = villainCards.some((vc) =>
        heroCards.some((hc) => isSameCard(vc, hc))
      )
      if (hasDuplicateWithHero) {
        setVillainError('Hero and Villain cannot have the same cards.')
        valid = false
      }
    }

    return valid
  }

  function dealToPlayers() {
    if (!validateCardsInput()) {
      setDealing(false)
      return
    }

    console.log('Dealing to players...button at seat:', selectedButton)
    setDealing(true)
    let currentDeck = [...deck]
    const updatedPlayers = [...playersWithCards]

    const numPlayers = pokerPlayers.length
    const startSeat = (selectedButton % numPlayers) + 1

    console.log('Button is at seat:', selectedButton)
    console.log('Starting seat is:', startSeat)

    let dealCount = 0

    const heroPlayer = updatedPlayers.find((p) => p.seat === selectedHero)
    const villainPlayer = updatedPlayers.find((p) => p.seat === selectedVillain)

    let heroCards = []
    let villainCards = []

    // Deal hero cards, either provided or random
    if (heroCardsInput) {
      heroCards = heroCardsInput.split(' ').map(parseCard)
      heroPlayer.cards = heroCards
      currentDeck = currentDeck.filter((card) => !heroCards.some((hc) => isSameCard(hc, card)))
    } else if (selectedHero) {
      heroCards = [currentDeck.shift(), currentDeck.shift()]
      heroPlayer.cards = heroCards
    }

    // Deal villain cards, either provided or random
    if (villainCardsInput) {
      villainCards = villainCardsInput.split(' ').map(parseCard)
      villainPlayer.cards = villainCards
      currentDeck = currentDeck.filter((card) => !villainCards.some((vc) => isSameCard(vc, card)))
    } else if (selectedVillain) {
      villainCards = [currentDeck.shift(), currentDeck.shift()]
      villainPlayer.cards = villainCards
    }

    function dealCardToPlayer() {
      if (dealCount >= 16) {
        setDeck(currentDeck)
        setPlayersWithCards(updatedPlayers)
        setDealing(false)
        setDealStage('flop')
        return
      }

      const playerSeat = ((startSeat + dealCount - 1) % numPlayers) + 1
      const player = updatedPlayers.find((p) => p.seat === playerSeat)

      if (
        player &&
        currentDeck.length > 0 &&
        player.seat !== selectedHero &&
        player.seat !== selectedVillain
      ) {
        player.cards.push(currentDeck.shift())
        setPlayersWithCards([...updatedPlayers])
      }

      dealCount++
      setTimeout(dealCardToPlayer, 100)
    }

    dealCardToPlayer()
  }

  function handleReset() {
    const newDeck = createDeck()
    shuffleDeck(newDeck)
    setDeck(newDeck)
    setDealStage('preflop')
    setPlayersWithCards(pokerPlayers.map((player) => ({ ...player, cards: [] })))
    setCommunityCards([])
    setDealing(false)
    setHeroCardsInput('') // Reset hero input
    setVillainCardsInput('') // Reset villain input
    setHeroError('') // Reset hero error
    setVillainError('') // Reset villain error
  }

  function dealFlop() {
    setDealing(true)
    const burnCard = deck[0] // Burn the first card
    const currentDeck = deck.slice(1) // Remove the burn card
    let currentFlop = []
    let dealCount = 0

    function dealFlopCard() {
      if (dealCount >= 3) {
        setCommunityCards([...currentFlop]) // Set the community cards for the flop
        setDeck(currentDeck.slice(3)) // Remove dealt cards from deck
        setDealing(false) // Stop dealing animation
        setDealStage('turn') // Move to the turn stage
        return
      }

      currentFlop.push(currentDeck[dealCount]) // Add a card to the flop
      setCommunityCards([...currentFlop]) // Update state to trigger render

      dealCount++
      setTimeout(dealFlopCard, 300) // Deal the next card after 500ms delay
    }

    dealFlopCard() // Start dealing the flop
  }

  function dealTurn() {
    setDealing(true)
    const burnCard = deck[0] // Burn the first card
    const currentDeck = deck.slice(1) // Remove the burn card

    setTimeout(() => {
      const dealtTurn = currentDeck[0] // Deal the next card for the turn
      setCommunityCards((prevCards) => [...prevCards, dealtTurn])
      setDeck(currentDeck.slice(1)) // Remove dealt card from deck
      setDealing(false) // Stop dealing animation
      setDealStage('river') // Move to the river stage
    }, 500) // Delay of 500ms
  }

  function dealRiver() {
    setDealing(true)
    const burnCard = deck[0] // Burn the first card
    const currentDeck = deck.slice(1) // Remove the burn card

    setTimeout(() => {
      const dealtRiver = currentDeck[0] // Deal the next card for the river
      setCommunityCards((prevCards) => [...prevCards, dealtRiver])
      setDeck(currentDeck.slice(1)) // Remove dealt card from deck
      setDealing(false) // Stop dealing animation
      setDealStage('complete') // Mark game as complete
    }, 500) // Delay of 500ms
  }

  function updateButtonPosition(seat) {
    setSelectedButton(seat)
    console.log(`Button is set to seat ${seat}`)
  }

  function handleSubmit(e) {
    e.preventDefault()
    handleReset()
    dealToPlayers()
  }

  return (
    <div className="poker-table--wrapper">
      <div className="poker-app--background">
        <div className="poker-table--container">
          <img
            className="poker-table--table-image"
            src="./assets/table-nobg-svg-01.svg"
            alt="Poker Table"
          />
          <div className="input-container">
            <div className="inner-input">
              <form onSubmit={handleSubmit}>
                <table>
                  <thead>
                    <tr>
                      <th>Seat</th>
                      <th>Button</th>
                      <th>Hero</th>
                      <th>Villain</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pokerPlayers.map((player) => (
                      <tr key={player.seat}>
                        <td>{player.seat}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedButton === player.seat}
                            onChange={() =>
                              setSelectedButton(selectedButton === player.seat ? null : player.seat)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedHero === player.seat}
                            onChange={() =>
                              setSelectedHero(selectedHero === player.seat ? null : player.seat)
                            }
                            disabled={selectedVillain === player.seat}
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedVillain === player.seat}
                            onChange={() =>
                              setSelectedVillain(
                                selectedVillain === player.seat ? null : player.seat
                              )
                            }
                            disabled={selectedHero === player.seat}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div>
                  <input
                    id="heroCards"
                    type="text"
                    value={heroCardsInput}
                    placeholder="Hero Cards (e.g., Ac Th)"
                    onChange={(e) => setHeroCardsInput(e.target.value)}
                    disabled={!selectedHero}
                  />
                  {heroError && <div className="error-message">{heroError}</div>}{' '}
                  {/* Error message for hero cards */}
                  <input
                    id="villainCards"
                    type="text"
                    value={villainCardsInput}
                    placeholder="Villain Cards (e.g., Ks Qd)"
                    onChange={(e) => setVillainCardsInput(e.target.value)}
                    disabled={!selectedVillain}
                  />
                  {villainError && <div className="error-message">{villainError}</div>}{' '}
                  {/* Error message for villain cards */}
                </div>

                <div className="form-group">
                  <button
                    onClick={() =>
                      dealStage === 'preflop'
                        ? dealToPlayers({ button: selectedButton })
                        : dealStage === 'flop'
                          ? dealFlop()
                          : dealStage === 'turn'
                            ? dealTurn()
                            : dealStage === 'river'
                              ? dealRiver()
                              : null
                    }
                    disabled={dealing || dealStage === 'complete'}
                  >
                    {dealStage === 'preflop'
                      ? 'Deal to Players'
                      : dealStage === 'flop'
                        ? 'Deal Flop'
                        : dealStage === 'turn'
                          ? 'Deal Turn'
                          : dealStage === 'river'
                            ? 'Deal River'
                            : 'All Cards Dealt'}
                  </button>

                  <button onClick={handleReset} disabled={dealing || dealStage === 'preflop'}>
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>

          <Dealer />

          {playersWithCards.map((player, index) => (
            <Player
              key={index}
              player={player}
              button={selectedButton}
              updateButton={updateButtonPosition}
              hero={selectedHero}
              villain={selectedVillain}
              deck={deck}
            />
          ))}

          <div className="community-card-container">
            {communityCards.map((card, index) => (
              <div
                key={index}
                className={`playing-card ${card.suit === '♥' || card.suit === '♦' ? 'red-card' : 'black-card'}`}
              >
                {card.value}
                {card.suit}
              </div>
            ))}
          </div>

          <div className="pot-container">
            <img src="./assets/pot.svg" alt="Pot Value" style={{ height: '55px', width: '55px' }} />
            <h4>0</h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PokerSim
