import React from 'react'
import '../Poker.css' // Assuming CSS is in a separate file
import { useState } from 'react'

function Player({ player, button, hero, villain, deck, updateButton }) {
  const [isHovered, setIsHovered] = useState(false) // State to track hover status
  const isHero = player.seat === hero
  const isVillain = player.seat === villain

  // Define the position names
  const positions = ['SB', 'BB', 'UTG', '+1', 'LJ', 'HJ', 'CO', 'B']

  // Function to calculate a player's relative position based on the button
  function getPosition(seat, button, numPlayers) {
    const indexOffset = (seat - button + numPlayers) % numPlayers
    return positions[(indexOffset + numPlayers - 1) % numPlayers] // Adjusted to handle button correctly
  }

  // Calculate the position for the current player
  const positionName = getPosition(player.seat, button, 8) // Assuming there are 8 players

  return (
    <div className={`player-entity--wrapper p${player.seat}`}>
      <div className="actionBox transitionable-actionBox-exit-done">Call 20</div>
      <div className={`player-cards-container ${player.folded ? 'folded' : ''}`}>
        <div className="player-avatar--container">
          <img
            className="player-avatar--image"
            style={{ opacity: player.folded ? 0.5 : 1 }}
            src={player.avatarUrl}
            alt="Player Avatar"
          />
          <h5
            className="player-info--name"
            style={{
              backgroundColor: isHovered
                ? 'blue' // Background color when hovered
                : isHero
                  ? 'green' // Hero background color
                  : isVillain
                    ? 'red' // Villain background color
                    : '', // Default background color
              cursor: 'pointer', // Change cursor to pointer on hover to indicate clickability
            }}
            onMouseEnter={() => setIsHovered(true)} // Set hover state to true on mouse enter
            onMouseLeave={() => setIsHovered(false)} // Set hover state to false on mouse leave
            onClick={() => updateButton(player.seat)} // Set player as button on double click
            title="Double-click to set as button" // Optional tooltip
          >
            {/* Conditionally show "BUTTON" when hovered */}
            {isHovered
              ? 'BUTTON'
              : isHero
                ? 'HERO'
                : isVillain
                  ? 'VILLAIN'
                  : button === null || button === undefined
                    ? player.name
                    : positionName}{' '}
          </h5>
        </div>
        <div className="centered-flex-row abscard">
          {player.cards.map((card, index) => (
            <div
              className={`playing-card cardIn robotcard ${isHero ? 'visible-card' : ''} ${
                card.suit === '♥' || card.suit === '♦' ? 'red-card' : 'black-card'
              }`} // Add class based on the card's suit
              key={index}
            >
              <span className="card-details">
                {card.value} {card.suit}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="player-info--container">
        <div className="player-info--stash--container">
          <img className="player-info--stash--image" src="./assets/chips.svg" alt="Player Stash" />
          <h5>{player.stack}</h5>
        </div>
        <div className="player-info--bet--container">
          <img className="player-info--bet--image" src="./assets/bet.svg" alt="Player Bet" />
          <h5>Bet: 20</h5>
        </div>
        {player.seat === button && (
          <div className="dealer-chip-icon-container">
            <img src="/assets/chip.svg" alt="Dealer Chip" />
          </div>
        )}
      </div>
    </div>
  )
}

export default Player
