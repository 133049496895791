import React from 'react'
import '../App.css'
import '../Poker.css'
import '../HomePage.css'

function HomePage() {
  return (
    <div className="homepage">
      <div className="hero-section">
        <div className="hero-content">
          <h1>Floptimize</h1>
          <p>Explore our services and offerings. We are here to help you with all your needs.</p>
          {/* <a href="/about" className="hero-button">
            Learn More
          </a> */}
        </div>
      </div>
      <div className="content-section">
        <div className="content">
          <h2>Our Services</h2>
          <p>
            We offer a wide range of services to meet your needs, including web development, design,
            and consulting. Our team is dedicated to providing the best possible solutions for your
            business.
          </p>
        </div>
        <div className="content">
          <h2>About Us</h2>
          <p>
            With years of experience in the industry, we are committed to delivering top-notch
            services. Our experts are here to guide you every step of the way.
          </p>
        </div>
      </div>
    </div>
  )
}

export default HomePage
