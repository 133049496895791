function Dealer() {
  return (
    <div className="player-entity--wrapper pd">
      <div className="player-entity--container">
        <div className="player-avatar--container">
          <img className="player-avatar--image" src="./assets/boy.svg" alt="Player Avatar" />
          <h5 className="player-info--name" style={{ backgroundColor: '#fff', color: '#000' }}>
            Dealer
          </h5>
        </div>
      </div>
    </div>
  )
}

export default Dealer
