function getRandomStack(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const pokerPlayers = [
  {
    name: 'Seat 1',
    avatarUrl: 'https://randomuser.me/api/portraits/men/97.jpg',
    seat: 1,
    stack: getRandomStack(1000, 5000),
    folded: false,
  },
  {
    name: 'Seat 2',
    avatarUrl: 'https://randomuser.me/api/portraits/men/47.jpg',
    seat: 2,
    stack: getRandomStack(1000, 5000),
    folded: false,
  },
  {
    name: 'Seat 3',
    avatarUrl: 'https://randomuser.me/api/portraits/men/17.jpg',
    seat: 3,
    stack: getRandomStack(1000, 5000),
    folded: false,
  },
  {
    name: 'Seat 4',
    avatarUrl: 'https://randomuser.me/api/portraits/women/47.jpg',
    seat: 4,
    stack: getRandomStack(1000, 5000),
    folded: false,
  },
  {
    name: 'Seat 5',
    avatarUrl: 'https://randomuser.me/api/portraits/men/12.jpg',
    seat: 5,
    stack: getRandomStack(1000, 5000),
    folded: false,
  },
  {
    name: 'Seat 6',
    avatarUrl: 'https://randomuser.me/api/portraits/men/44.jpg',
    seat: 6,
    stack: getRandomStack(1000, 5000),
    folded: false,
  },
  {
    name: 'Seat 7',
    avatarUrl: 'https://randomuser.me/api/portraits/women/87.jpg',
    seat: 7,
    stack: getRandomStack(1000, 5000),
    folded: false,
  },
  {
    name: 'Seat 8',
    avatarUrl: 'https://randomuser.me/api/portraits/men/77.jpg',
    seat: 8,
    stack: getRandomStack(1000, 5000),
    folded: false,
  },
]
