import './App.css'
import './Poker.css'
import PokerSim from './components/PokerSim'
import HomePage from './components/HomePage'
import Navbar from './components/Navbar'
import NotFoundPage from './components/NotFoundPage'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import React from 'react'

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar /> {/* A common navbar for navigation */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/sim" element={<PokerSim />} />
          {/* Add more routes as needed */}
          <Route path="*" element={<NotFoundPage />} /> {/* 404 page */}
        </Routes>
      </div>
    </Router>
  )
}

export default App
